export default function initNavigation() {
  const header = document.querySelector('.header');
  const navigation = document.querySelector('.header__menu-mobile');
  const navigation_btn = document.querySelector('.header__menu-btn');
  const body = document.body;
  const html = document.documentElement;
  const main = document.querySelector('main');

  navigation_btn.addEventListener('click', () => {
    navigation.classList.toggle('active');
    navigation_btn.classList.toggle('active');
    body.classList.toggle('ov-hidden');
    html.classList.toggle('ov-hidden');
  });

  window.addEventListener('scroll', () => {
    const scroll = window.scrollY;
    if (scroll >= header.offsetHeight) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  });

  document.addEventListener(
    'wpcf7submit',
    function () {
      const responseOutput = document.querySelector('.wpcf7-response-output');
      const yOffset = -200;
      const y = responseOutput.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
      window.scrollTo({top: y, behavior: 'smooth'});
    },
    false
  );

  // window.addEventListener('load', () => {
  //   main.style.paddingTop = `${header.offsetHeight}px`;
  // });

  // window.addEventListener('resize', () => {
  //   main.style.paddingTop = `${header.offsetHeight}px`;
  // });
}
