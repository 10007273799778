import Swiper from 'swiper';
import { Autoplay } from 'swiper';

export default function initRunningLine() {
  const communitySwiper = document.querySelectorAll('.running-line');
  if (!communitySwiper) return;

  communitySwiper.forEach((el) => {
    const slider = el.querySelector('.running-line-swiper');

    var swiper = new Swiper(slider, {
      modules: [Autoplay],
      slidesPerView: "auto",
      spaceBetween: 30,
      loop: true,
      centeredSlides: true,
      speed: 7000,
      autoplay: {
        delay: 0,
      }
    });
  });
}
