export default function initFilterScroll() {
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('category[]')) {
            var blogSection = document.querySelector('.blog');
            window.scrollTo({
                top: blogSection.offsetTop - 100,
                behavior: 'smooth'
            });
        }
}
  