import Swiper from 'swiper';
import { Navigation } from 'swiper';

export default function initTestimonialsSwiper() {
    const communitySwiper = document.querySelectorAll('.testimonials');
    if (!communitySwiper) return;

    communitySwiper.forEach((el) => {
      const slider = el.querySelector('.testimonials__swiper');

      var swiper = new Swiper(slider, {
        modules: [Navigation],
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          767: {
            slidesPerView: 1.715,
            spaceBetween: 30
          }
        },
        navigation: {
            nextEl: ".testimonials__navigation-button-next",
            prevEl: ".testimonials__navigation-button-prev",
        }
      });
  });
}