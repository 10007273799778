import initPreloader from './modules/preolader';
import initNavigation from './modules/navigation';
import initSwiper from './modules/swiper';
import initAccordion from './modules/accordion';
import initRunningLine from './modules/running-line';
import initFilterScroll from './modules/filter-scroll';
import initTestimonialsSwiper from './modules/testimonials-swiper';
import initNavigationArticle from './modules/navigation-article';


import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  initPreloader();
  initSwiper();
  initNavigation();
  initAccordion();
  initRunningLine();
  initFilterScroll();
  initTestimonialsSwiper();
  initNavigationArticle();
});
