export default function initNavigationArticle() {
  if (document.querySelector('.article')) {
    if (document.querySelector('.article__content')) {
      const routeContainer = document.querySelector('.article__list');
      const headingsH2 = document.querySelector('.article__content').querySelectorAll('h2');
      if (headingsH2) {
        if (headingsH2.length == 0) {
          document.querySelector('.article__list').style.display = 'none';
          document.querySelector('.article__aside-title').style.display = 'none';
        } else {
          headingsH2.forEach((item, index) => {
            routeContainer.innerHTML += `<li>${item.textContent}</li> `;
          });
          const allHeadeingContainer = routeContainer.querySelectorAll('li');
          for (let i = 0; i < allHeadeingContainer.length; i++) {
            allHeadeingContainer[i].addEventListener('click', function () {
              allHeadeingContainer.forEach((item) => {
                item.style.fontStyle = 'normal';
                item.style.textDecoration = 'none';
              });
              this.style.fontStyle = 'italic';
              this.style.textDecoration = 'underline';
              window.scroll(0, findPosition(headingsH2[i]) - 100);
    
              function findPosition(obj) {
                var currenttop = 0;
                if (obj.offsetParent) {
                  do {
                    currenttop += obj.offsetTop;
                  } while ((obj = obj.offsetParent));
                  return [currenttop];
                }
              }
            });
          }
        }
      }
    } else {
      document.querySelector('.article__list').style.display = 'none';
      document.querySelector('.article__aside-title').style.display = 'none';
    }
  }
}
