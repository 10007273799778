import Swiper from 'swiper';
import { Navigation } from 'swiper';

export default function initSwiper() {
    const communitySwiper = document.querySelectorAll('.slider');
    if (!communitySwiper) return;

    communitySwiper.forEach((el) => {
      const slider = el.querySelector('.slider__swiper-image');

      var swiper = new Swiper(slider, {
        modules: [Navigation],
        allowTouchMove : false,
        navigation: {
            nextEl: el.querySelector(".slider__navigation-button-next"),
            prevEl: el.querySelector(".slider__navigation-button-prev"),
        }
      });
  });

  communitySwiper.forEach((el) => {
    const slider = el.querySelector('.slider__swiper-text');

    var swiper = new Swiper(slider, {
      modules: [Navigation],
      allowTouchMove : false,
      navigation: {
          nextEl: el.querySelector(".slider__navigation-button-next"),
          prevEl: el.querySelector(".slider__navigation-button-prev"),
      }
    });
});
}